<template>
<div id="">
  <navbar></navbar>
  <v-container >
    <v-layout justify-center pa-5 row wrap>
      <FbLogin class="button" id="FBLogin"
      appId="384524882195740"
      @login="getUserData"
      @logout="onLogout"
      @sdk-loaded="saveSDK"
      >
    </FbLogin>
    <!-- <v-flex xs12>
      <div class="" id="token">
        {{userToken}}
      </div>
    </v-flex>
     -->
     <v-layout row wrap v-if="userToken!=null">
       <v-flex xs12>
         <h3>
           <div class="">
             Share on your page
           </div>
         </h3>
       </v-flex>
       <v-flex xs10 justify-center>
         <v-text-field
         v-model="fbText"
         label="enter your text you want to share with the link"
         ></v-text-field>
         <v-text-field
         v-model="fbLink"
         label="enter the link you want to share: (leave blank if you don't want to share a link)"
         >
         </v-text-field>
       </v-flex>
       <v-flex xs11 justify-center>
         <v-btn color="primary" @click="postToFb" v-if="posting==false && userToken!=null">Post to your fb page: {{pageName}}</v-btn>

       </v-flex>
     </v-layout>
     <!-- <v-layout row wrap id="token">
       {{userToken}}
     </v-layout> -->
    <v-flex xs10 justify-space-around v-if="postId!=null">
      postID: {{postId}}
      <br>post URL: {{postURL}}
    </v-flex>
    </v-layout>

  </v-container>

</div>
</template>
<script>
import axios from 'axios'
import FbLogin from 'facebook-login-vuejs';

export default {
  name: "",
  data: () => ({
    sdk:null,
    userToken:null,
    pageToken:null,
    posting:false,
    postId:null,
    postURL:null,
    fbLink:null,
    pageName:null,
    fbText:"",
    url:'https://blog.myroomabroad.com',
    postInf:{
      // text: 'blabla',
      url:'https://blog.myroomabroad.com'
    }
  }),
  computed:{
  },
  methods: {
    getUserData(payload){
      console.log(payload, ', getUserData');
      if (payload.hasOwnProperty('response')){
        this.userToken=payload.response.authResponse.accessToken
        let url='https://graph.facebook.com/me/accounts?access_token='+this.userToken
        axios.get(url)
        .then(res=>{
          console.log(res.data)
          for (var i = 0; i < res.data.data.length; i++) {
            this.pageName=res.data.data[0].name
            this.pageToken=res.data.data[0].access_token
          }
        })
      }
      this.sdk=payload.FB
      if (this.sdk!=null && this.userToken!=null){
        console.log(this.userToken);
      }
    },
    onLogout(payload){
      console.log('logout payload: ', payload);
    },
    saveSDK(payload){
      console.log(payload);
      this.sdk=payload.FB
      console.log(this.sdk);
    },
    postToFb(){
      this.posting=true
      let data={
        message:this.fbText,
        link:this.fbLink,
        access_token:this.pageToken
      }
      let url='https://graph.facebook.com/me/feed'
      console.log('postToURL',url);
      axios.post(url,data)
      .then(res=>{
        this.postId=res.data.id
        let url2='https://graph.facebook.com/'+this.postId+'?fields=permalink_url'+'&access_token='+this.userToken
        axios.get(url2)
        .then(res=>{
          this.postURL=res.data.permalink_url
        })
        .catch(err=>{
          console.log(err);
          alert(err)
          this.posting=false
        })
      })
      .catch(err=>{
        console.log(err);
        alert(err)
        this.posting=false
      })
    }
  },
  components: {
    FbLogin
  },

}
</script>
  
